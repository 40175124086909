import React from "react"
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types'
import List from './list'


const LinkedBlurbs = ({ blurbs }) => {


    if (!blurbs) {
      return null;
    } else { return (
      <>
      {blurbs.map((blurb, index) => (
        <div key={blurb.heading}>
          <hr className="featurette-divider"/>

          <div className="row featurette">
            <div className={ "col-md-7 " + (index%2 === 0 ? "" :  "order-md-2")}>
            <Link to={blurb.pageLink} className="text-decoration-none text-reset">
                <h2 className="featurette-heading">{blurb.heading}</h2>
                <h2 className="text-muted">{blurb.subheading}</h2>
                <p className="lead">
                  {blurb.text}
                </p>
                <List items={blurb.list} />
              </Link>
            </div>
            <div className={"col-md-5 " + (index%2 === 0 ? "" :  "order-md-1")}>
              <Link to={blurb.pageLink}><Img fluid={blurb.image.childImageSharp.fluid} className="d-block img-fluid rounded" alt={blurb.alt} /></Link>

            </div>
          </div>
        </div>
      ))}
      </>

    );
  }
};

LinkedBlurbs.propTypes = {
    blurbs: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        alt: PropTypes.string,
        pageLink: PropTypes.string,
        heading: PropTypes.string,
        subheading: PropTypes.string,
        text: PropTypes.string,
        list: PropTypes.array,
      })
    ),
}

export default LinkedBlurbs
