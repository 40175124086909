import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import Carousel from '../components/carousel'
import SEO from '../components/seo'
import LinkedBlurbs from '../components/linked-blurb'


export const query = graphql`
  query ($id: String!) {
    markdownRemark( id: { eq: $id } ) {

        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          heading
          subheading
          mainpitch {
            title
            description
          }
          description
          intro {
            slides {
              image {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              pageLink
              alt
              heading
              description
            }
            blurbs {
              image {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              pageLink
              heading
              subHeading
              text
            }
            
          }
        }
    }
  }
`

const ImageBackground = styled(BackgroundImage)`
  background-position: top 20% center;
  background-size: cover;
  height: 50vh;

  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;

const Banner = ({
  fluid,
  alt,
  heading,
  subheading
}) => {

  return (
    <ImageBackground Tag="section" fluid={fluid} alt={alt} fadeIn="soft">
        <div className="container pt-4">
          <h1 className="display-2 text-light"><strong>{heading}</strong></h1>
          <h2 className="display-5 text-light"><strong>{subheading}</strong></h2>
        </div>
    </ImageBackground>
  );
};

Banner.propTypes = {
  fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

export const IndexPageTemplate = ({
  image,
  alt,
  heading,
  subheading,
  mainpitch,
  intro
}) => {
      return (
      
      <>
        <Banner 
          fluid={image.childImageSharp.fluid} 
          alt={alt}
          heading={heading}
          subheading={subheading}
          />
        <div className="container marketing">
          <div className="mt-5">
              <h2 className="text-center">
                {mainpitch.title}
                <br/>
                {mainpitch.description}
              </h2>
          </div>
          <hr className="featurette-divider"/>
          <Carousel slides={intro.slides} />
          <LinkedBlurbs blurbs={intro.blurbs} />

          <hr className="featurette-divider"/>

        </div>
      </>
    );
  }

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    slides: PropTypes.array,
    blurbs: PropTypes.array
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
    <SEO 
      title={frontmatter.title}
      description={frontmatter.description}
    />      
      <IndexPageTemplate
        image={frontmatter.image}
        alt={frontmatter.alt}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage


