import React from "react"
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types'


const Carousel = ({ slides }) => {

  return (

  <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
    {slides.map((slide, index) => (
      <div className={"carousel-item " + (index === 0 ? "active" : "")} key={slide.heading}>
      <Img fluid={slide.image.childImageSharp.fluid}
        className="carousel-image"
        objectFit="cover"
        objectPosition="50% 50%"
        alt={slide.alt}/>
        <div className="container">
          <div className="carousel-caption">
            <Link to={slide.pageLink} className="text-decoration-none text-reset">
              <div className="text-block">
                <h2 className="display-3">{slide.heading}</h2>
                <p>{slide.description}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    ))}
    </div>

    <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
  );
};

Carousel.propTypes = {
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        alt: PropTypes.string,
        pageLink: PropTypes.string,
        heading: PropTypes.string,
        description: PropTypes.string,
      })
    ),
}

export default Carousel
